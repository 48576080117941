import React, { useState } from 'react';
import './Navigation.scss';
import logo from '../../assets/images/Viking-logo.svg';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const Navigation: React.FC = () => {
  const [isScrooled, setIsScrolled] = useState(false);

  window.onscroll = () => {
    setIsScrolled(window.scrollY > 0);
  };

  return (
    <div>
      <nav className={`Navigation navbar navbar-expand-lg ${isScrooled ? 'scrolled' : ''}`}>
        <div className="navbar-brand">
          <AnchorLink href="#home">
            <img src={logo} />
          </AnchorLink>
        </div>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <FontAwesomeIcon icon={faBars} color='silver' />
        </button>
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav">
            <li className="nav-item active">
              <AnchorLink href="#aboutUs" offset="50" className="nav-link">About us</AnchorLink>
            </li>
            <li className="nav-item">
              <AnchorLink href="#joinUs" offset="122" className="nav-link">Application</AnchorLink>
            </li>
            <li className="nav-item">
              <AnchorLink href="#ourEquipment" offset="50" className="nav-link">Equipment</AnchorLink>
            </li>
            <li className="nav-item">
              <AnchorLink href="#leaseOrRent" offset="122" className="nav-link">Lease or rent trucks</AnchorLink>
            </li>
            <li className="nav-item">
              <AnchorLink href="#repairShop" offset="50" className="nav-link">Repair shop</AnchorLink>
            </li>
            <li className="nav-item">
              <AnchorLink href="#trucksOnSale" offset="122" className="nav-link">Trucks on sale</AnchorLink>
            </li>
            <li className="nav-item">
              <AnchorLink href="#contactUs" offset="50" className="nav-link">Contact us</AnchorLink>
            </li>
          </ul>
        </div>
      </nav>
      <div className={`join-us ${isScrooled ? 'decrease' : ''}`}>
        <AnchorLink href="#joinUs" offset="122" className="nav-link">Join Us</AnchorLink>
      </div>
    </div>
  );
}

export default Navigation;