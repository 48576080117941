import React from 'react';
import './TrucksOnSale.scss';

import t1_01 from '../../assets/images/Trucks/t1_01.jpg';
import t1_02 from '../../assets/images/Trucks/t1_02.jpg';
import t1_03 from '../../assets/images/Trucks/t1_03.jpg';
import t1_04 from '../../assets/images/Trucks/t1_04.jpg';
import t1_05 from '../../assets/images/Trucks/t1_05.jpg';
import t1_06 from '../../assets/images/Trucks/t1_06.jpg';
import t1_07 from '../../assets/images/Trucks/t1_07.jpg';

import t2_01 from '../../assets/images/Trucks/t2_01.jpg';
import t2_02 from '../../assets/images/Trucks/t2_02.jpg';
import t2_03 from '../../assets/images/Trucks/t2_03.jpg';
import t2_04 from '../../assets/images/Trucks/t2_04.jpg';
import t2_05 from '../../assets/images/Trucks/t2_05.jpg';
import t2_06 from '../../assets/images/Trucks/t2_06.jpg';
import t2_07 from '../../assets/images/Trucks/t2_07.jpg';
import t2_08 from '../../assets/images/Trucks/t2_08.jpg';

import t3_01 from '../../assets/images/Trucks/t3_01.jpg';
import t3_02 from '../../assets/images/Trucks/t3_02.jpg';
import t3_03 from '../../assets/images/Trucks/t3_03.jpg';
import t3_04 from '../../assets/images/Trucks/t3_04.jpg';
import t3_05 from '../../assets/images/Trucks/t3_05.jpg';
import t3_06 from '../../assets/images/Trucks/t3_06.jpg';
import t3_07 from '../../assets/images/Trucks/t3_07.jpg';
import t3_08 from '../../assets/images/Trucks/t3_08.jpg';

import t4_01 from '../../assets/images/Trucks/t4_01.jpg';
import t4_02 from '../../assets/images/Trucks/t4_02.jpg';
import t4_03 from '../../assets/images/Trucks/t4_03.jpg';
import t4_04 from '../../assets/images/Trucks/t4_04.jpg';
import t4_05 from '../../assets/images/Trucks/t4_05.jpg';

import t5_01 from '../../assets/images/Trucks/t5_01.jpg';
import t5_02 from '../../assets/images/Trucks/t5_02.jpg';
import t5_03 from '../../assets/images/Trucks/t5_03.jpg';
import t5_04 from '../../assets/images/Trucks/t5_04.jpg';
import t5_05 from '../../assets/images/Trucks/t5_05.jpg';
import t5_06 from '../../assets/images/Trucks/t5_06.jpg';
import t5_07 from '../../assets/images/Trucks/t5_07.jpg';
import t5_08 from '../../assets/images/Trucks/t5_08.jpg';
import t5_09 from '../../assets/images/Trucks/t5_09.jpg';
import t5_10 from '../../assets/images/Trucks/t5_10.jpg';
import t5_11 from '../../assets/images/Trucks/t5_11.jpg';
import t5_12 from '../../assets/images/Trucks/t5_12.jpg';
import t5_13 from '../../assets/images/Trucks/t5_13.jpg';
import t5_14 from '../../assets/images/Trucks/t5_14.jpg';
import t5_15 from '../../assets/images/Trucks/t5_15.jpg';
import t5_16 from '../../assets/images/Trucks/t5_16.jpg';
import t5_17 from '../../assets/images/Trucks/t5_17.jpg';
import t5_18 from '../../assets/images/Trucks/t5_18.jpg';
import t5_19 from '../../assets/images/Trucks/t5_19.jpg';
import t5_20 from '../../assets/images/Trucks/t5_20.jpg';
import t5_21 from '../../assets/images/Trucks/t5_21.jpg';

import Truck from '../Truck/Truck';

const TrucksOnSale: React.FC = () => {

  const trucks = [
    {
      name: '2016 Volvo VNL670',
      mileage: '419,449 mi',
      horsepower: '425hp',
      description: 'Manual OD 10 Speed; Air Ride Suspension; Polished Aluminum Wheels; RED Color; Tandem Axle; D-13 Volvo Engine 425 hp; Diesel Fuel Type; 12,500 lb Front Axle Weight; 40,000 lb Rear Axle Weight; Drive Side: Left Hand Drive; VIN #: 4V4NC9EH8GN180653;',
      price: 'On request',
      stockNo: '2016VNL670-419449',
      images: [t1_01, t1_02, t1_03, t1_04, t1_05, t1_06, t1_07]
    },
    {
      name: '2015 Volvo VNL670',
      mileage: '401,540 mi',
      horsepower: '425hp',
      description: 'Manual OD 10 Speed; Air Ride Suspension; Polished Aluminum Wheels; RED Color; Tandem Axle; D-13 Volvo Engine 425 hp; Diesel Fuel Type; 12,500 lb Front Axle Weight; 40,000 lb Rear Axle Weight; Drive Side: Left Hand Drive; VIN #: 4V4NC9EH8FN180523;',
      price: 'On request',
      stockNo: '2015VNL670-401540',
      images: [t2_01, t2_02, t2_03, t2_04, t2_05, t2_06, t2_07, t2_08]
    },
    {
      name: '2015 Volvo VNL670',
      mileage: '419,500 mi',
      horsepower: '425hp',
      description: 'Manual OD 10 Speed; Air Ride Suspension; Polished Aluminum Wheels; RED Color; Tandem Axle; D-13 Volvo Engine 425 hp; Diesel Fuel Type; 12,500 lb Front Axle Weight; 40,000 lb Rear Axle Weight; Drive Side: Left Hand Drive; VIN #: 4V4NC9EH6FN180567;',
      price: 'On request',
      stockNo: '2015VNL670-419500',
      images: [t3_01, t3_02, t3_03, t3_04, t3_05, t3_06, t3_07, t3_08]
    },
    {
      name: '2015 Volvo VNL670',
      mileage: '392,262 mi',
      horsepower: '425hp',
      description: 'Manual OD 10 Speed; Air Ride Suspension; Polished Aluminum Wheels; RED Color; Tandem Axle; D-13 Volvo Engine 425 hp; Diesel Fuel Type; 12,500 lb Front Axle Weight; 40,000 lb Rear Axle Weight; Drive Side: Left Hand Drive; VIN #: 4V4NC9EH7FN912477;',
      price: 'On request',
      stockNo: '2015VNL670-392262',
      images: [t4_01, t4_02, t4_03, t4_04, t4_05]
    },
    {
      name: '2 X 2014 Volvo VNL780',
      mileage: '-',
      horsepower: '455hp',
      description: 'Automatic i-shift; Air Ride Suspension; Polished Aluminum Wheels; WHITE Color; Tandem Axle; D-13 Volvo Engine 455 hp; Diesel Fuel Type; 13,200 lb Front Axle Weight; 40,000 lb Rear Axle Weight; Drive Side: Left Hand Drive; VIN #: 4V4NC9EH2EN172464, 4V4NC9EH9EN172462;',
      price: 'On request',
      stockNo: '2014VNL670-X2',
      images: [t5_01, t5_02, t5_03, t5_04, t5_05, t5_06, t5_07, t5_08, t5_09, t5_10, t5_11, t5_12, t5_13, t5_14, t5_15, t5_16, t5_17, t5_18, t5_19, t5_20, t5_21]
    }
  ];

  return (
    <div className="TrucksOnSale row no-gutters" id="trucksOnSale">
      <div className="col-lg-7 col-12 trucks">
        {trucks.map((t, i) =>
          <Truck truck={t} key={i} />
        )}
      </div>

      < div className="col-lg-5 col-12 description" >
        <h3>Take a look</h3>
        <h1>Trucks on sale</h1>
        <p>Are you in the market for a used, well-maintained tractor? Check out our equipment for sale to see what we have available.</p>
        <p>We offer a variety of Volvos for sale, well maintained, affordable and ready to be put on the road. Contact us for more information.</p>
      </div >
    </div >
  );
}

export default TrucksOnSale;