import React, { useReducer, useEffect } from 'react';

import { validate } from '../../util/validators';
import './Input.scss';

const inputReducer = (state, action) => {
  switch (action.type) {
    case 'CHANGE':
      return {
        ...state,
        value: action.val,
        isValid: validate(action.val, action.validators)
      };
    case 'TOUCH': {
      return {
        ...state,
        isTouched: true
      }
    }
    default:
      return state;
  }
};

const Input = props => {
  const [inputState, dispatch] = useReducer(inputReducer, {
    value: props.initialValue || '',
    isTouched: false,
    isValid: props.initialValid || false
  });

  const { id, onInput } = props;
  const { value, isValid } = inputState;

  useEffect(() => {
    onInput(id, value, isValid)
  }, [id, value, isValid, onInput]);

  const changeHandler = event => {
    dispatch({
      type: 'CHANGE',
      val: event.target.value,
      validators: props.validators
    });
  };

  const touchHandler = () => {
    dispatch({
      type: 'TOUCH'
    });
  };

  const element =
    props.element === 'input' ? (
      <input
        className={`form-control form-control-sm ${!inputState.isValid && inputState.isTouched &&
          'form-control--invalid'}`}
        id={props.id}
        type={props.type}
        placeholder={props.placeholder}
        onChange={changeHandler}
        onBlur={touchHandler}
        value={inputState.value}
      />
    ) : props.element === "select" ? (
      <select
        className="form-control form-control-sm"
        id={props.id}
        placeholder={props.placeholder}
        onChange={changeHandler}
        onBlur={touchHandler}
        value={inputState.value}
      >
        <option value="" disabled>{props.defaultOption}</option>
        {props.options.map((state, i) =>
          <option value={state} key={i}>{state}</option>
        )}
      </select>
    ) : (
          <textarea
            className={`form-control form-control-sm ${!inputState.isValid && inputState.isTouched &&
              'form-control--invalid'}`}
            id={props.id}
            placeholder={props.placeholder}
            rows={props.rows || 3}
            onChange={changeHandler}
            onBlur={touchHandler}
            value={inputState.value}
          />
        );

  return (
    <div className="form-group">
      <label htmlFor={props.id}>{props.label}</label>
      {element}
      {!inputState.isValid && inputState.isTouched && <span className="validationError">{props.errorText}</span>}
    </div>
  );
};

export default Input;
