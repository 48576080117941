import React from 'react';
import './OurEquipment.scss';
import vikingTrucksImg from '../../assets/images/viking-trucks.jpg';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const OurEquipment: React.FC = () => {

  return (
    <div className="OurEquipment row no-gutters" id="ourEquipment">
      <div className="col-lg-5 col-12 description">
        <h3>Learn more about</h3>
        <h1>Our Equipment</h1>
        <p>At Viking transportation, we have the right equipment and experience to provide the utmost reliable transportation service.</p>
        <p>Most of our equipment are Volvos which include vnla60, vnl670, and VNL780, powered by automatic and manual transmissions. We offer 53’ dry van trailers clean and in excellent condition.</p>
        <p>We maintenance our equipment in our shop where we perform routine and scheduled maintenance programs to ensure top quality D.O.T compliant equipment.</p>
        <div className="contact-us">
          <AnchorLink href="#leaseOrRent" offset="122" className="nav-link">Lease or rent truck</AnchorLink>
        </div>
      </div>

      <div className="col-lg-7 col-12 image" data-aos="zoom-in">
        <img src={vikingTrucksImg} alt='Viking trucks' />
      </div>

    </div>
  );
}

export default OurEquipment;
