import React from 'react';
import './RepairShop.scss';
import repairShopImg from '../../assets/images/repair-shop.jpg';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const RepairShop: React.FC = () => {

  return (
    <div className="RepairShop row no-gutters" id="repairShop">
      <div className="col-lg-5 col-12 description">
        <h3>If you need</h3>
        <h1>Truck repair shop</h1>
        <p>Our trained technicians at Viking are trained and certified with the latest software. We inspect all our equipment to ensure our equipment is safe and running perfectly when it hits the road!</p>
        <p>We maintenance company trucks and welcome walk-in customers or by appointment.</p>
        <p>We offer 24hr road service near NW Indiana and Chicagoland area, as well as 300$/daily towing hook rentals.</p>
        <div className="contact-us">
          <AnchorLink href="#contactUs" className="nav-link">Contact us</AnchorLink>
        </div>
      </div>

      <div className="col-lg-7 col-12 image" data-aos="zoom-in">
        <img src={repairShopImg} alt='Repair shop image' />
      </div>
    </div>
  );
}

export default RepairShop;