import React from 'react';
import './AboutUs.scss';
import aboutUsImg from '../../assets/images/about-us.jpg';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const AboutUs: React.FC = (props) => {

  return (
    <div className="AboutUs row no-gutters" id="aboutUs">
      <div className="col-lg-5 col-12 description">
        <h3>Come and join us</h3>
        <h1>We are Viking</h1>
        <p>Our business is a family owned and operated business since 2016, at Viking Transportation we see our customers and employees as part of our family, we work together as a team to provide the best service to our customers.</p>
        <p>In today’s world distance does not create an issue in business deals between companies. Technology and communication create closer business relations between business partners which most of the time are in different parts of the country. Requirements for quality are more and more wanted, and good quality is hard to find. To have a good quality relationship between partners, professionalism needs to be on the highest level, and that is what we provide.</p>
        <p>Every day we work towards earning the customers trust and confidence. We take pride in providing the best service to our customers with our experienced drivers and excellent on time service. The goal at Viking transportation is to build and maintain strong relations by exceeding all expectations. We understand that our customers expect what is promised and we do our best to do just that.</p>
        <div className="contact-us">
          <AnchorLink href="#contactUs" offset="50" className="nav-link">Contact us</AnchorLink>
        </div>
      </div>

      <div className="col-lg-7 col-12 image" data-aos="zoom-in">
        <img src={aboutUsImg} alt='About us image' />
      </div>
    </div>
  )
}

export default AboutUs;