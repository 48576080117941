import React from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";

const Footer: React.FC = () => {
  return (
    <div className="Footer">
      <p>2020 © Viking Trasportation INC. All Rights Reserved.{" "}</p>
      <Link to="/privacy-policy">Read our Privacy Policy</Link>
    </div>
  );
};

export default Footer;
