import React from 'react';
import { Modal } from 'react-bootstrap';
import './NotificationModal.scss';

const NotificationModal = props => {

  return (
    <Modal
      {...props}
      aria-labelledby="notification-modal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="notification-modal">
          {props.heading}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{props.message}</p>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="modal-apply-btn" onClick={props.onHide}>Close</button>
      </Modal.Footer>
    </Modal>

  )
}

export default NotificationModal;

