import React, { useState } from 'react';
import './Application.scss';
import { useForm } from '../../shared/hooks/form-hook';
import Input from '../../shared/FormElements/Input/Input';
import { VALIDATOR_REQUIRE, VALIDATOR_EMAIL, VALIDATOR_MINLENGTH } from '../../shared/util/validators';
import axios from 'axios';
import NotificationModal from '../../shared/NotificationModal/NotificationModal';

const Application = props => {

  const [modalShow, setModalShow] = useState(false);

  const { position } = props;
  const states = [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming'
  ];

  const experience = [
    '2', '3', '4', '5', '6', '7', '8', '9', '10+'
  ];

  const violation = [ 'Yes', 'No' ];

  const [formState, inputHandler] = useForm(
    {
      name: {
        value: '',
        isValid: false
      },
      phone: {
        value: '',
        isValid: false
      },
      email: {
        value: '',
        isValid: false
      },
      state: {
        value: '',
        isValid: false
      },
      date: {
        value: '',
        isValid: false
      },
      experience: {
        value: '',
        isValid: false
      },
      violation: {
        value: '',
        isValid: false
      },
      message: {
        value: '',
        isValid: false
      }
    },
    false
  );

  const formSubmit = (e) => {
    e.preventDefault();

    // setModalShow(true);

    let data = {
      subject: `Message from ${position} Application form`,
      name: formState.inputs.name.value,
      phone: formState.inputs.phone.value,
      email: formState.inputs.email.value,
      state: formState.inputs.state.value,
      date: formState.inputs.date.value,
      experience: formState.inputs.experience.value,
      violation: formState.inputs.violation.value,
      message: formState.inputs.message.value
    }

    axios.post(process.env.REACT_APP_BACKEND_URL + '/application', data)
      .then(res => {
        // resetForm()
        setModalShow(true);
      })
      .catch(() => {
        console.log('Message not sent')
      })
  }


  return (
    <React.Fragment>
      <form className="Application" onSubmit={(e) => formSubmit(e)}>
        <p>Please fill out the Application Form and our recruiters will contact you ASAP</p>

        <Input
          id="name"
          element="input"
          type="text"
          label="Name"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Please enter your name."
          onInput={inputHandler}
        />
        <Input
          id="phone"
          element="input"
          type="tel"
          label="Phone"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Please enter your phone number."
          onInput={inputHandler}
        />
        <Input
          id="email"
          element="input"
          type="email"
          label="Email"
          validators={[VALIDATOR_EMAIL()]}
          errorText="Please enter a valid email address."
          onInput={inputHandler}
        />
        <Input
          id="state"
          element="select"
          label="State"
          onInput={inputHandler}
          defaultOption=""
          options={states}
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Please chose you state."
        />
        <Input
          id="date"
          element="input"
          type="date"
          label="Date of birth"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Please enter your birth date."
          onInput={inputHandler}
        />
        <Input
          id="experience"
          element="select"
          label="Years of experience"
          onInput={inputHandler}
          defaultOption=""
          options={experience}
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Please chose how many years of experience you have."
        />
        <Input
          id="violation"
          element="select"
          label="Do you have more than 3 violations in past 4 years."
          onInput={inputHandler}
          defaultOption=""
          options={violation}
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Please chose do you have more than 3 violations in past 4 years."
        />
        <Input
          id="message"
          element="textarea"
          rows={5}
          label="Additional informations"
          validators={[VALIDATOR_MINLENGTH(5)]}
          errorText="Please enter your additional informations. (At least 5 charactes)"
          onInput={inputHandler}
        />
        <button type="submit" className="apply-btn"
          disabled={!formState.isValid}
        >Apply now</button>
      </form>
      <NotificationModal
        heading="Application"
        message="Thank you for filling up our application! Your application will be processed by our team members, and soon someone from our team will reach you."
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </React.Fragment>

  )
}

export default Application;