import React, { useState } from 'react';
import './Truck.scss';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const Truck: React.FC<{ truck: { name: string, mileage: string, horsepower: string, description: string, price: string, stockNo: string, images: Array<string> } }> = ({ truck }) => {

  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  return (
    <div className="row no-gutters truck">
      <img src={truck.images[0]} className="col-lg-4 displayedImg" onClick={() => setIsOpen(true)} />

      {isOpen && (
        <Lightbox
          mainSrc={truck.images[photoIndex]}
          nextSrc={truck.images[(photoIndex + 1) % truck.images.length]}
          prevSrc={truck.images[(photoIndex + truck.images.length - 1) % truck.images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + truck.images.length - 1) % truck.images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % truck.images.length)
          }
        />
      )}
      <div className="col-lg-6 details">
        <h3><b>{truck.name}</b></h3>
        <p><b>Stock number:</b> {truck.stockNo}</p>
        <p><b>Mileage:</b> {truck.mileage}</p>
        <p><b>Horsepower:</b> {truck.horsepower}</p>
        <p><b>Description:</b> {truck.description}</p>
      </div>
      <div className="col-lg-2 price">
        <p><b>Price:</b></p>
        <h3 className="price"><b>{truck.price}</b></h3>
        <div className="contact-us">
          <AnchorLink href="#contactUs" className="nav-link">Contact us</AnchorLink>
        </div>
      </div>
    </div>
  )
}

export default Truck;