import React from "react";
import "./App.scss";
import Home from "./components/Home/Home";
import Navigation from "./components/Navigation/Navigation";
import AboutUs from "./components/AboutUs/AboutUs";
import JoinUs from "./components/JoinUs/JoinUs";
import OurEquipment from "./components/OurEquipment/OurEquipment";
import LeaseOrRent from "./components/LeaseOrRent/LeaseOrRent";
import RepairShop from "./components/RepairShop/RepairShop";
import TrucksOnSale from "./components/TrucksOnSale/TrucksOnSale";
import ContactUs from "./components/ContactUs/ContactUs";
import Footer from "./components/Footer/Footer";
import Divider from "./components/Divider/Divider";
import AOS from "aos";
import "aos/dist/aos.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";

function App() {
  AOS.init();

  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Navigation />
                <Home />
                <Divider />
                <AboutUs />
                <Divider />
                <JoinUs />
                <Divider />
                <OurEquipment />
                <Divider />
                <LeaseOrRent />
                <Divider />
                <RepairShop />
                <Divider />
                <TrucksOnSale />
                <Divider />
                <ContactUs />
                <Footer />
              </>
            }
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
